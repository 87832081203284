import Form from '@searchtrends/webform-processor';

window.axios = require('axios');

window.hideGoogleMaps = function () {
    document.cookie = "showGoogleMaps=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict";

    location.reload();
}

window.allowGoogleMaps = function () {
    document.cookie = "showGoogleMaps=" + Math.floor(Date.now() / 1000) + "; path=/; SameSite=Strict";
}

window.loadMapsJs = function() {
    let token = document.head.querySelector('meta[name="maps-token"]');
    let script = document.createElement('script');

    script.src = 'https://maps.googleapis.com/maps/api/js?key=' + token.content;

    script.async = true;
    script.defer = true;

    document.head.appendChild(script);
    script.onload = () => {
        initMap();
    }
}

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

if (document.querySelector('.menubutton')) {
    document.querySelector('.menubutton').addEventListener('click', function (e) {
        e.preventDefault();
        console.log('click!!');

        document.querySelectorAll('nav .nav ul li').forEach(function (menuitem) {
            console.log(menuitem);
            menuitem.classList.toggle('menu-visible');
        })
    });
}

document.querySelectorAll('nav .nav ul li a').forEach(function (element) {
    element.addEventListener('mouseover', function (e) {
        if (element.dataset.title) {
            document.querySelector('.nav-title').innerHTML = element.dataset.title;
        }
    })
})

if (document.querySelector('.faq-button')) {
    document.querySelectorAll('.faq-button').forEach(function (button) {
        button.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelectorAll('.faq-answer').forEach(function (link) {
                link.classList.add('hidden');
            });

            if (document.querySelector(`#answer_${button.dataset.questionId}`)) {
                document.querySelector(`#answer_${button.dataset.questionId}`).classList.remove('hidden');
            }
        })
    });
}

if (document.querySelector('.company-submenu')) {
    let googleMaps = document.querySelector('#google-maps');
    let kontakt = document.querySelector('#kontakt');
    let offnungszeiten = document.querySelector('#businesshours');

    document.querySelector('#show-google-maps').addEventListener('click', function (e) {
        console.log('maps');
        googleMaps.classList.remove('hidden');
        kontakt.classList.add('hidden');
        offnungszeiten.classList.add('hidden');
    });

    document.querySelector('#show-contact').addEventListener('click', function (e) {
        console.log('kontakt');
        googleMaps.classList.add('hidden');
        kontakt.classList.remove('hidden');
        offnungszeiten.classList.add('hidden');
    });

    if (document.querySelector('#show-businesshours')) {
        document.querySelector('#show-businesshours').addEventListener('click', function (e) {
            console.log('offnungszeiten');
            googleMaps.classList.add('hidden');
            kontakt.classList.add('hidden');
            offnungszeiten.classList.remove('hidden');
        });
    }
}
